import React from "react";
import Button from "@mui/material/Button";


const TimeSelector = ({timeSelectorParams, updateTimeSelectorParams}) => {
    return <>
        <Button
            variant={"text"}
            sx={timeSelectorParams === "last year" ? {textDecoration: 'underline'} : {}}
            style={{marginTop: 5, marginBottom: 20, marginLeft: 15}}
            onClick={() => updateTimeSelectorParams('last year')}

        >Last year</Button>
        <Button
            variant={"text"}
            sx={timeSelectorParams === ">2024" ? {textDecoration: 'underline'} : {}}
            style={{marginTop: 5, marginBottom: 20, marginLeft: 15}}
            onClick={() => updateTimeSelectorParams('>2024')}

        >>2024</Button>
        <Button
            variant={"text"}
            sx={timeSelectorParams === null ? {textDecoration: 'underline'} : {}}
            style={{marginTop: 5, marginBottom: 20, marginLeft: 15}}
            onClick={() => updateTimeSelectorParams(null)}
        >All Time</Button>
    </>
}

export default TimeSelector;