import React from "react";
import withDataFetchAndTitle from "../../components/DataLoadPage";
import WorkforcePageAlexStyle from "../../components/workforce/WorkforcePageAlexStyle";

let path = 'productivity/workforce';

const ProductivityWorkforcePage = ({data}) => {
    return <>
        <WorkforcePageAlexStyle data={data}/>
    </>
}


export default withDataFetchAndTitle(ProductivityWorkforcePage, path, 'Productivity - Workforce')

