import * as React from "react";
import StandardPlot from "../../../components/Plots/StandardPlot/StandardPlot";
import {sampleData} from "../../../components/Plots/StandardPlot/sampleData";


const ExperimentsPage = () => {
    return <StandardPlot
        data={sampleData}
        title={"Stanard Plot"}
    />
    // return <Paper sx={
    //     {
    //         p: 2,
    //         display: 'flex',
    //         flexDirection: 'column',
    //         height: 500,
    //         marginBottom: '50px'
    //     }
    // }>
    //     <BumpPlot data={bumpData}/>
    // </Paper>
}

export default ExperimentsPage