import { CssBaseline } from "@mui/material";
import { Amplify } from 'aws-amplify';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { COGNITO } from "./configs/aws";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/dashboard/Dashboard";
import SignIn from "./pages/signin/SignIn";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";

Amplify.configure({
    Auth: {
        Cognito: {
            userPoolId: COGNITO.USER_POOL_ID,
            userPoolClientId: COGNITO.APP_CLIENT_ID,
            region: COGNITO.REGION
        }
    },
    API: {
        REST: {
            blah: {
                endpoint: "https://frbfjwsr5f.execute-api.us-east-1.amazonaws.com/prod/"
            },
            data: {
                endpoint: "https://j8g3iabdu1.execute-api.us-east-1.amazonaws.com/prod/"
            }
        }
    }
});

cognitoUserPoolsTokenProvider.setKeyValueStorage(window.localStorage);

function App() {
    return (
        <Router>
            <CssBaseline/>
            <Routes>
                <Route path='/signin' element={<SignIn/>}/>
                <Route path='*' element={<ProtectedRoute component={Dashboard}/>}/>
            </Routes>
        </Router>
    );
}

export default App;