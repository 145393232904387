import React from "react";
import {capY, convert} from "../../../components/Plots/GraphComponent/GraphComponent.helpers";
import MultiLineGraphComponent from "../../../components/Plots/MultiLinePlot/GraphComponent";
import moment from "moment/moment";
import {noFormat} from "../../../components/Plots/TimeLinePlot/timeLinePlotHelpers";
import {MyCustomPoint} from "../../../components/Plots/StandardPlot/MyCustomPoint";


export const YearGraph = ({
                              series, title,
                              format = (v) => parseInt(moment(v).format("YYYY")) - 1,
                              minY = -Infinity,
                              maxY = Infinity,
                              yFormatFun = noFormat,
                              colors
                          }) => {
    return <MultiLineGraphComponent
        data={Object.keys(series).map(key => ({
            id: key,
            data: capY(convert(series[key]), maxY, yFormatFun, yFormatFun)
        }))}
        title={title}
        xaxis={``}
        axisBottomParams={{
            format: format,
            legend: 'year',
            legendOffset: -12,
        }
        }
        pointSymbol={MyCustomPoint}
        colors={colors}
    />
}

