import React from "react";
import {convert} from "../../../../components/Plots/GraphComponent/GraphComponent.helpers";
import TimeBarWithPrint from "../../../../components/Plots/TimeBar/TimeBar";
import withDataFetchAndTitle from "../../../components/DataLoadPage";


const Graphs = ({data}) => {
    return <>
        <TimeBarWithPrint
            data={convert(data['weekly_sales'])}
            title={'Consumer Edge - Spend YoY growth [%]'}
            // xaxis={'# sales team churn [calendar year]'}
            timeFormat={'YY-MM-DD'}
            buttonStyle={{marginTop: '10px'}}
        />
    </>
}


export default withDataFetchAndTitle(Graphs, 'amzn/sales', 'AMZN - CE Weekly Sales')
