export class ViewCreatorInterface {
    constructor() {
        if (this.constructor === ViewCreatorInterface) {
            throw new Error('Cannot initialize abstract view creator')
        }
    }

    create(params) {
        throw new Error('Method not implemented');
    }

}