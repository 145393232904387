export const projectsData = [
    {
        projectName: 'TDG',
        urlPrefix: 'flight_data',
        icon: {type: 'import', name: 'TDGIcon'},
        views: [
            {
                title: 'SRS Forward', url: 'srs_forward_graphs', icon: {type: 'mui_font', name: 'fast_forward'},
                viewDefinition: {name: 'SRSForwardGraphsPage'},
            },
            {
                title: 'Cirium Flights', url: 'cirium_flights', icon: {type: 'mui_font', name: 'ssid_chart'},
                viewDefinition: {name: 'CiriumFlightsPage'},
            },
            {
                title: 'Regional Flights', url: 'regional_flights', icon: {type: 'mui_font', name: 'ssid_chart'},
                viewDefinition: {name: 'RegionalFlightsPage'},
            },
            {
                title: 'SRS Routes', url: 'srs_routes', icon: {type: 'mui_font', name: 'table_chart'},
                viewDefinition: {name: 'SRSRoutesPage'},
            },
            {
                title: 'Flight Radar', url: 'flight_radar', icon: {'type': 'mui_font', name: 'radar'},
                viewDefinition: {name: 'FlightRadarPage'},
            },
            {
                title: 'TSA', url: 'tsa', icon: {'type': 'mui_font', name: 'local_police'},
                viewDefinition: {name: 'TSAPage'},
            },
            {
                title: 'ASM History', url: 'asm', icon: {'type': 'mui_font', name: 'history'},
                viewDefinition: {name: 'ASMPage'},
            },
            {
                title: 'Fleet Age', url: 'fleet_age', icon: {type: 'mui_font', name: 'calendar_month'},
                viewDefinition: {name: 'ASMPage'},
            },
            {
                title: 'Orders', url: 'orders', icon: {type: 'mui_font', name: 'shopping_cart_checkout'},
                viewDefinition: {name: 'FlightDataOrdersPage'},
            },
            {
                title: 'First Flights', url: 'first_flights', icon: {type: 'mui_font', name: 'flight_takeoff'},
                viewDefinition: {name: 'FlightDataFirstFlightsPage'},
            },


            // {
            //     title: 'UBS', url: 'flight_data/ubs', icon: {type: 'import', name: 'SsidChartIcon'},
            //     viewDefinition: {name: 'UBSPage'}
            // },


        ]
    },
    {
        projectName: 'BILL',
        urlPrefix: 'billdotcom',
        icon: {type: 'import', name: 'BillDotComIcon'},
        views: [
            {
                title: 'Semrush', url: 'semrush', icon: {type: 'mui_font', name: 'ssid_chart'},
                viewDefinition: {name: 'BillSemrushPage'},
            },
            {
                title: 'Divvy AppAnnie', url: 'divvy', icon: {type: 'import', name: 'AppAnnieIcon'},
                viewDefinition: {name: 'DivvyPage'},
            },

        ]
    },
    {
        projectName: 'GTLB',
        urlPrefix: 'gitlab',
        icon: {type: 'import', name: 'GitlabIcon'},
        views: [
            {
                title: 'Sales Team', url: 'sales', icon: {type: 'mui_font', name: 'attach_money'},
                viewDefinition: {name: 'GitlabSales'},
            },
            {
                title: 'Category planning', url: 'categories', icon: {type: 'mui_font', name: 'timeline'},
                viewDefinition: {name: 'GitlabPlanningPage'},
            },
        ]
    },
    {
        projectName: 'AMZN',
        urlPrefix: 'amzn',
        icon: {type: 'import', name: 'AmazonIcon'},
        views: [
            {
                title: 'CE Weekly Sales', url: 'ce_sales', icon: {type: 'mui_font', name: 'timeline'},
                viewDefinition: {name: 'AmznCESalesPage'},
            },
            {
                title: 'CE Competitors', url: 'ce_competitors', icon: {type: 'mui_font', name: 'timeline'},
                viewDefinition: {name: 'CECompetitors'},
            },
            {
                title: 'Buy With Prime', url: 'buy_with_prime', icon: {type: 'import', name: 'AmazonShopifyIcon'},
                viewDefinition: {name: 'BuyWithPrimePage'},
            },
        ]
    },
    {
        projectName: 'DDOG',
        urlPrefix: 'datadog',
        icon: {type: 'import', name: 'DatadogIcon'},
        views: [
            {
                title: 'Workforce', url: 'workforce', icon: {type: 'mui_font', name: 'group'},
                viewDefinition: {name: 'DatadogWorkforcePage'},
            },
            {
                title: 'HG Insights', url: 'hginsights', icon: {type: 'mui_font', name: 'insights'},
                viewDefinition: {name: 'DatadogHGInsightsPage'},
            },
            {
                title: 'Semrush', url: 'semrush', icon: {type: 'mui_font', name: 'ssid_chart'},
                viewDefinition: {name: 'DatadogSemrush'},
            },
            {
                title: 'G2 - Buyer Intent', url: 'g2_buyer_intent', icon: {type: 'mui_font', name: 'ssid_chart'},
                viewDefinition: {name: 'DatadogBuyerIntentPage'},
            },
            {
                title: 'CNCF observability', url: 'cncf_observability', icon: {type: 'mui_font', name: 'visibility'},
                viewDefinition: {name: 'DatadogCncfGithub'},
            },
            {
                title: 'Open Telemetry',
                url: 'open_telemetry',
                icon: {type: 'import', name: 'DDOGOpenTelemetryLogoComponent'},
                viewDefinition: {name: 'DatadogOpenTelemetryGithub'},
            },
            {
                title: 'Security Sales', url: 'security_sales', icon: {type: 'mui_font', name: 'local_police'},
                viewDefinition: {name: 'DatadogSecuritySales'},
            },
        ]
    },
    {
        projectName: 'ZI',
        urlPrefix: 'zoominfo',
        icon: {type: 'import', name: 'ZoominfoIcon'},
        views: [
            {
                title: 'Workforce', url: 'workforce', icon: {type: 'mui_font', name: 'group'},
                viewDefinition: {
                    name: 'WorkforceView',
                    params: {dataPath: 'zoominfo/workforce', title: 'Zoominfo - Workforce'}
                },
            },
        ]
    },
    {
        projectName: 'PCOR',
        urlPrefix: 'procore',
        icon: {type: 'import', name: 'ProcoreIcon'},
        views: [
            {
                title: 'AppAnnie', url: 'app_annie', icon: {type: 'import', name: 'AppAnnieIcon'},
                viewDefinition: {
                    name: 'StackedView',
                    params: {dataPath: 'procore/app_annie_v2', title: 'Procore - AppAnnie'}
                },
            },
            {
                title: 'AppAnnie ADSK', url: 'app_annie_adsk', icon: {type: 'import', name: 'AppAnnieIcon'},
                viewDefinition: {
                    name: 'StackedView',
                    params: {dataPath: 'procore/app_annie_adsk', title: 'Procore - AppAnnie ADSK'}
                },
            },
            {
                title: 'Workforce', url: 'workforce', icon: {type: 'mui_font', name: 'group'},
                viewDefinition: {
                    name: 'WorkforceView',
                    params: {dataPath: 'procore/workforce', title: 'Procore - Workforce'}
                },
            },
        ]
    },
    {
        projectName: 'ADSK',
        urlPrefix: 'autodesk',
        icon: {type: 'import', name: 'AutodeskIcon'},
        views: [
            {
                title: 'G2 - Buyer Intent', url: 'g2_buyer_intent', icon: {type: 'mui_font', name: 'ssid_chart'},
                viewDefinition: {name: 'AutodeskBuyerIntentPage'},
            },
            {
                title: 'G2 - Reviews', url: 'g2', icon: {type: 'mui_font', name: 'ssid_chart'},
                viewDefinition: {name: 'AutodeskG2Page'},
            },
            {
                title: 'Semrush', url: 'semrush', icon: {type: 'mui_font', name: 'ssid_chart'},
                viewDefinition: {name: 'AutodeskSemrush'},
            },
        ]
    },
    {
        projectName: 'GWRE',
        urlPrefix: 'guidewire',
        icon: {type: 'import', name: 'GuidewireIcon'},
        views: [
            {
                title: 'Workforce', url: 'workforce', icon: {type: 'mui_font', name: 'group'},
                viewDefinition: {
                    name: 'WorkforceView',
                    params: {dataPath: 'guidewire/workforce', title: 'Guidewire - Workforce'}
                },
            },
        ]
    },
    {
        projectName: 'Shopify',
        urlPrefix: 'shopify',
        icon: {type: 'import', name: 'ShopifyIcon'},
        views: [
            {
                title: 'Built with', url: 'builtwith', icon: {type: 'mui_font', name: 'construction'},
                viewDefinition: {name: 'ShopifyBuiltwithPage'},
            },
            {
                title: 'AppAnnie', url: 'app_annie', icon: {type: 'import', name: 'AppAnnieIcon'},
                viewDefinition: {
                    name: 'AppAnnieDexcomView', params: {
                        dataPath: 'shopify/app_annie_competitors', title: 'Shopify Competitors - App Annie',
                        graphParams: {cumulativeGraphs: false}
                    }
                },
            },
            {
                title: 'PublicWWW', url: 'public_www', icon: {type: 'mui_font', name: 'construction'},
                viewDefinition: {name: 'ShopifyPublicWWW'},
            },
        ]
    },
    {
        projectName: 'DXCM',
        urlPrefix: 'dexcom',
        icon: {type: 'import', name: 'DexcomIcon'},
        views: [
            {
                title: 'AppAnnie', url: 'app_annie', icon: {type: 'import', name: 'AppAnnieIcon'},
                viewDefinition: {
                    name: 'AppAnnieDexcomView', params: {
                        dataPath: 'dexcom/app_annie', title: 'DXCM - App Annie'
                    }
                },
            },
            {
                title: 'Stelo AppAnnie', url: 'stelo_app_annie', icon: {type: 'import', name: 'AppAnnieIcon'},
                viewDefinition: {
                    name: 'AppAnnieDexcomView', params: {
                        dataPath: 'dexcom/stelo_app_annie', title: 'DXCM - Stelo - App Annie',
                        graphParams: {cumulativeGraphs: false, regions: ['WW']}
                    }
                },
            },
        ]
    },
    {
        projectName: 'Costar',
        urlPrefix: 'costar',
        icon: {type: 'import', name: 'CostarIcon'},
        views: [
            {
                title: 'Semrush', url: 'semrush', icon: {type: 'mui_font', name: 'ssid_chart'},
                viewDefinition: {
                    name: 'SemrushGraphsView', params: {
                        dataPath: 'costar/semrush', title: 'Costar - Semrush',
                    },
                }
            },
        ]
    },
    {
        divider: 'divide'
    },
    {
        projectName: 'MNDY',
        urlPrefix: 'productivity',
        icon: {type: 'import', name: 'MondayDotComIcon'},
        views: [
            {
                title: 'AppAnnie', url: 'app_annie', icon: {type: 'mui_font', name: 'ssid_chart'},
                viewDefinition: {name: 'ProductivityAppAnnieSummary'},
            },
            {
                title: 'Semrush', url: 'semrush', icon: {type: 'mui_font', name: 'ssid_chart'},
                viewDefinition: {name: 'ProductivitySemrushPage'},
            },
            {
                title: 'G2', url: 'g2', icon: {type: 'mui_font', name: 'ssid_chart'},
                viewDefinition: {name: 'ProductivityG2Page'},
            },
            {
                title: 'G2 - Buyer Intent', url: 'g2_buyer_intent', icon: {type: 'mui_font', name: 'ssid_chart'},
                viewDefinition: {name: 'BuyerIntentPage'},
            },
            {
                title: 'Built with', url: 'builtwith', icon: {type: 'mui_font', name: 'construction'},
                viewDefinition: {name: 'BuiltwithPage'},
            },
            {
                title: 'HG Insights', url: 'hginsights', icon: {type: 'mui_font', name: 'insights'},
                viewDefinition: {name: 'ProductivityHGInsightsPage'},
            },
            {
                title: 'Workforce', url: 'workforce', icon: {type: 'mui_font', name: 'group'},
                viewDefinition: {name: 'ProductivityWorkforcePage'},
            },
            {
                title: 'Indeed', url: 'indeed', icon: {type: 'import', name: 'IndeedComIcon'},
                viewDefinition: {name: 'MondayIndeed'},
            },
            {
                title: 'Acuity Linkedin', url: 'acuity_linkedin', icon: {type: 'mui_font', name: 'group'},
                viewDefinition: {name: 'MondayAcuityLinkedin'},
            },
            {
                title: 'Google Trends', url: 'google_trends', icon: {type: 'import', name: 'GoogleTrendsIcon'},
                viewDefinition: {name: 'MondayGoogleTrends'},
            },
        ]
    },
    {
        projectName: 'ETFs',
        urlPrefix: 'etf',
        icon: {type: 'import', name: 'ETFIcon'},
        views: [
            {
                title: 'Equal Weights', url: 'equal_weights', icon: {type: 'mui_font', name: 'scale'},
                viewDefinition: {name: 'ETFEqualWeightsPage'},
            },
            {
                title: 'SPY', url: 'spy', icon: {type: 'import', name: 'SpyIcon'},
                viewDefinition: {name: 'EtfSpy'},
            },
        ]
    },
    {
        projectName: 'IBKR',
        urlPrefix: 'ibkr',
        icon: {type: 'import', name: 'IBKRIcon'},
        views: [
            {
                title: 'AppAnnie', url: 'app_annie', icon: {type: 'import', name: 'AppAnnieIcon'},
                viewDefinition: {name: 'IBKRAppAnnie'},
            },
            {
                title: 'AppAnnie Summary', url: 'app_annie_summary', icon: {type: 'mui_font', name: 'ssid_chart'},
                viewDefinition: {name: 'IBKRAppAnnieSummary'},
            },
            {
                title: 'Semrush', url: 'semrush', icon: {type: 'mui_font', name: 'ssid_chart'},
                viewDefinition: {name: 'IBKRSemrush'},
            },
        ]
    },
    {
        projectName: 'SNOW',
        urlPrefix: 'snow',
        icon: {type: 'import', name: 'SNOWIcon'},
        views: [
            {
                title: 'Github Stars', url: 'github_stars', icon: {type: 'import', name: 'GithubIcon'},
                viewDefinition: {name: 'GithubStars'},
            },
            {
                title: 'DB Engines', url: 'db_engines', icon: {type: 'import', name: 'DatabaseIcon'},
                viewDefinition: {name: 'DBEngines'},
            },
            {
                title: 'HG Insights', url: 'hginsights', icon: {type: 'mui_font', name: 'insights'},
                viewDefinition: {name: 'SnowflakeHGInsightsPage'},
            },
            {
                title: 'Indeed', url: 'indeed', icon: {type: 'import', name: 'IndeedComIcon'},
                viewDefinition: {name: 'SnowIndeed'},
            },
        ]
    },
    {
        projectName: 'Loopnet',
        urlPrefix: 'loopnet',
        icon: {type: 'mui_font', name: 'location_city'},
        views: [
            {
                title: 'SF Offices', url: 'sf_offices', icon: {type: 'mui_font', name: 'timeline'},
                viewDefinition: {name: 'SFOfficesTable'},
            },
        ]
    },
    {
        projectName: 'MongoDB',
        urlPrefix: 'mongodb',
        icon: {type: 'import', name: 'MongoIcon'},
        views: [
            {
                title: 'Github Stars', url: 'github_stars', icon: {type: 'import', name: 'GithubIcon'},
                viewDefinition: {name: 'MongoGithubStars'},
            },
            {
                title: 'DB Engines', url: 'db_engines', icon: {type: 'import', name: 'DatabaseIcon'},
                viewDefinition: {name: 'MongoDBEngines'},
            },
            {
                title: 'Indeed', url: 'indeed', icon: {type: 'import', name: 'IndeedComIcon'},
                viewDefinition: {name: 'MongoIndeed'},
            },
            {
                title: 'Google Trends', url: 'google_trends', icon: {type: 'import', name: 'GoogleTrendsIcon'},
                viewDefinition: {name: 'MongoGoogleTrends'},
            },
            {
                title: 'StackOverflow', url: 'stackoverflow', icon: {type: 'import', name: 'StackoverflowIcon'},
                viewDefinition: {name: 'StackOverflowSurveyComponent'},
            },
        ]
    },
    {
        projectName: 'TSLA',
        urlPrefix: 'tesla',
        icon: {type: 'import', name: 'TeslaIcon'},
        views: [
            {
                title: 'AppAnnie', url: 'app_annie', icon: {type: 'import', name: 'AppAnnieIcon'},
                viewDefinition: {
                    name: 'AppAnnieDexcomView', params: {
                        dataPath: 'tesla/app_annie', title: 'TSLA - App Annie',
                        graphParams: {cumulativeGraphs: false}
                    }
                },
            },
        ]
    },
    {
        projectName: 'LLMs',
        urlPrefix: 'llms',
        icon: {type: 'import', name: 'LLMSIcon'},
        views: [
            {
                title: 'App Annie', url: 'app_annie', icon: {type: 'import', name: 'AppAnnieIcon'},
                viewDefinition: {
                    name: 'AppAnnieDexcomView', params: {
                        dataPath: 'llms/app_annie', title: 'LLMs - App Annie',
                        graphParams: {cumulativeGraphs: false}
                    }
                },
            },
            {
                title: 'Semrush', url: 'semrush', icon: {type: 'mui_font', name: 'ssid_chart'},
                viewDefinition: {name: 'LLMsSemrush'},
            },
        ]
    },
    {
        projectName: 'CPNG',
        urlPrefix: 'cpng',
        icon: {type: 'import', name: 'CPNGIcon'},
        views: [
            {
                title: 'App Annie', url: 'app_annie', icon: {type: 'import', name: 'AppAnnieIcon'},
                viewDefinition: {
                    name: 'AppAnnieDexcomView', params: {
                        dataPath: 'cpng/app_annie', title: 'Coupang - App Annie',
                        graphParams: {cumulativeGraphs: false, regions: ['WW']}
                    }
                },
            },
            {
                title: 'Semrush', url: 'semrush', icon: {type: 'mui_font', name: 'ssid_chart'},
                viewDefinition: {name: 'CPNGSemrush'},
            },
        ]
    },
    {
        projectName: 'Folio',
        urlPrefix: 'folio',
        icon: {type: 'import', name: 'FolioIcon'},
        views: [
            {
                title: 'Semrush', url: 'semrush', icon: {type: 'mui_font', name: 'ssid_chart'},
                viewDefinition: {name: 'FolioSemrush'},
            },
            {
                title: 'Semrush Folio Guard', url: 'semrush_folio_guard', icon: {type: 'mui_font', name: 'ssid_chart'},
                viewDefinition: {name: 'FolioGuardSemrush'},
            },
            {
                title: 'Built with', url: 'builtwith', icon: {type: 'mui_font', name: 'construction'},
                viewDefinition: {name: 'FolioBuiltWith'},
            },
        ]
    },
    {
        projectName: 'Privco',
        urlPrefix: 'privco',
        icon: {type: 'import', name: 'PrivcoIcon'},
        views: [
            {
                title: 'AI Companies', url: 'privco/ai_companies', icon: {type: 'mui_font', name: 'smart_toy'},
                viewDefinition: {name: 'PrivcoPage'}
            }
        ]
    },
    {
        divider: 'divide'
    },
    {
        projectName: 'Other',
        urlPrefix: 'other',
        icon: {type: 'mui_font', name: 'miscellaneous_services'},
        views: [
            {
                title: 'Indeed', url: 'indeed', icon: {type: 'import', name: 'IndeedComIcon'},
                viewDefinition: {name: 'OtherIndeed'},
            },
            {
                title: 'Acuity Linkedin', url: 'acuity_linkedin', icon: {type: 'mui_font', name: 'group'},
                viewDefinition: {name: 'OtherAcuityLinkedin'},
            },
        ]
    },
]