export const sampleData = {
    "asana.com": {
        "2022-09-15": 215.80274797570848,
        "2022-10-15": 304.3144430769231,
        "2022-11-15": 320.21342272406497,
        "2022-12-15": 142.24057254901962,
        "2023-01-15": 136.2950897812602,
        "2023-02-15": 377.31840163934424,
        "2023-03-15": 179.16611297915267,
        "2023-04-15": 305.8884155455904,
        "2023-05-15": 171.47923151125403,
        "2023-06-15": 161.7684451079467,
        "2023-07-15": 119.70719897858966,
        "2023-08-15": 147.17842424242423,
        "2023-09-15": 172.66684845112223,
        "2023-10-15": 137.56827731092437,
        "2023-11-15": 175.45640108954717,
        "2023-12-15": 266.7957195378151,
        "2024-01-15": 344.77391240875914,
        "2024-02-15": 393.5792496171516,
        "2024-03-15": 400.46932157394843,
        "2024-04-15": 412.26933884297523,
        "2024-05-15": 285.0002786573781,
        "2024-06-15": 284.4189847715736,
        "2024-07-15": 162.4039463854098,
        "2024-08-15": 396.30284858853724,
        "2024-09-15": 330.702693558881
    },
    "smartsheet.com": {
        "2022-09-15": 141.2651946262487,
        "2022-10-15": 239.06392774788623,
        "2022-11-15": 209.3927482128674,
        "2022-12-15": 123.9700999772779,
        "2023-01-15": 90.98248716067498,
        "2023-02-15": 182.36559009628613,
        "2023-03-15": 89.88431756756756,
        "2023-04-15": 89.14410009367054,
        "2023-05-15": 78.26936963537733,
        "2023-06-15": 101.36418771861639,
        "2023-07-15": 117.028158,
        "2023-08-15": 161.53207508731083,
        "2023-09-15": 94.40917494158879,
        "2023-10-15": 100.57131649650725,
        "2023-11-15": 174.5302944,
        "2023-12-15": 200.19078464106846,
        "2024-01-15": 178.93700909090907,
        "2024-02-15": 222.98299101412067,
        "2024-03-15": 291.5686401480111,
        "2024-04-15": 348.6518702651515,
        "2024-05-15": 227.64584210526317,
        "2024-06-15": 141.94068085106383,
        "2024-07-15": 201.91604912572856,
        "2024-08-15": 285.17455676516335,
        "2024-09-15": 218.72136239782017
    },
    "airtable.com": {
        "2022-09-15": 137.08829909613803,
        "2022-10-15": 561.4678044596913,
        "2022-11-15": 157.34472114137483,
        "2022-12-15": 93.53829508196722,
        "2023-01-15": 143.85166872682325,
        "2023-02-15": 88.47962905718701,
        "2023-03-15": 141.3889696969697,
        "2023-04-15": 109.36765171503957,
        "2023-05-15": 200.11888888888888,
        "2023-06-15": 351.4221126760563,
        "2023-07-15": 298.28230496453904,
        "2023-08-15": 387.6182786885246,
        "2023-09-15": 510.0113698630137,
        "2023-10-15": 215.32191387559809,
        "2023-11-15": 455.34090476190477,
        "2023-12-15": 2732.41358974359,
        "2024-01-15": 807.0013725490196,
        "2024-02-15": 361.01901098901095,
        "2024-03-15": 329.07,
        "2024-04-15": 288.1683333333333,
        "2024-05-15": 292.5973043478261,
        "2024-06-15": 357.1944117647059,
        "2024-07-15": 319.7061538461539,
        "2024-08-15": 289.6004615384615,
        "2024-09-15": 308.8823308270677
    },
    "monday.com": {
        "2022-09-15": 946.6953406531532,
        "2022-10-15": 805.3382149280575,
        "2022-11-15": 393.67765199161425,
        "2022-12-15": 269.1160511607911,
        "2023-01-15": 230.1409723976186,
        "2023-02-15": 285.2624288224956,
        "2023-03-15": 287.69570862541906,
        "2023-04-15": 550.9184453589392,
        "2023-05-15": 302.2105546700669,
        "2023-06-15": 299.5381164457962,
        "2023-07-15": 310.9030329474622,
        "2023-08-15": 340.7859288330717,
        "2023-09-15": 349.355611482501,
        "2023-10-15": 320.8772372742201,
        "2023-11-15": 418.1104048054322,
        "2023-12-15": 468.9220212765958,
        "2024-01-15": 465.75503198294246,
        "2024-02-15": 478.9841909090909,
        "2024-03-15": 531.9191693635382,
        "2024-04-15": 538.3474582848273,
        "2024-05-15": 365.3857563338301,
        "2024-06-15": 376.4057991692252,
        "2024-07-15": 291.7952456718301,
        "2024-08-15": 318.46047785469455,
        "2024-09-15": 420.8365731857318
    },
    "clickup.com": {
        "2022-09-15": 172.17141059255374,
        "2022-10-15": 281.17176751592353,
        "2022-11-15": 288.6450146627566,
        "2022-12-15": 180.88856658595643,
        "2023-01-15": 268.98695758166747,
        "2023-02-15": 197.86469072164948,
        "2023-03-15": 156.40080681818182,
        "2023-04-15": 102.21119879176892,
        "2023-05-15": 240.33594131614655,
        "2023-06-15": 299.6678102189781,
        "2023-07-15": 157.99065338645417,
        "2023-08-15": 272.5100691699605,
        "2023-09-15": 127.66405831903944,
        "2023-10-15": 98.4925585284281,
        "2023-11-15": 73.526057467424,
        "2023-12-15": 137.0800325203252,
        "2024-01-15": 126.57907366071429,
        "2024-02-15": 206.31164794007492,
        "2024-03-15": 177.92503184713377,
        "2024-04-15": 288.7009523809524,
        "2024-05-15": 174.29925490196078,
        "2024-06-15": 105.00884004884004,
        "2024-07-15": 141.49422176591375,
        "2024-08-15": 181.78164044943819,
        "2024-09-15": 151.3785724637681
    },
    "atlassian.com": {
        "2022-09-15": 289.2800371747212,
        "2022-10-15": 315.0914263685428,
        "2022-11-15": 499.24468551644543,
        "2022-12-15": 191.05146058732615,
        "2023-01-15": 116.68708294209702,
        "2023-02-15": 316.7169837716844,
        "2023-03-15": 186.1483078514772,
        "2023-04-15": 157.7652361581921,
        "2023-05-15": 130.23642913231254,
        "2023-06-15": 211.50930340845593,
        "2023-07-15": 171.45359081419625,
        "2023-08-15": 140.62102311643835,
        "2023-09-15": 121.86118489583333,
        "2023-10-15": 117.80356645569621,
        "2023-11-15": 133.88092738645634,
        "2023-12-15": 188.1846350078493,
        "2024-01-15": 269.2313825275657,
        "2024-02-15": 239.18374999999997,
        "2024-03-15": 203.3990808543097,
        "2024-04-15": 299.71119298245617,
        "2024-05-15": 259.45129388816645,
        "2024-06-15": 223.7537621732445,
        "2024-07-15": 200.7144596012592,
        "2024-08-15": 320.7189207735496,
        "2024-09-15": 363.93944415851774
    },
    "trello.com": {
        "2022-09-15": 1340.5,
        "2022-10-15": 1290.5441891891892,
        "2022-11-15": 1650.4394047619048,
        "2022-12-15": 892.1447126436783,
        "2023-01-15": 636.3619841269841,
        "2023-02-15": 578.5545454545455,
        "2023-03-15": 514.6199342105263,
        "2023-04-15": 698.3046666666667,
        "2023-05-15": 1128.5111111111112,
        "2023-06-15": 983.4392700729926,
        "2023-07-15": 1148.63968,
        "2023-08-15": 2539.5310526315793,
        "2023-09-15": 3344.0607407407406,
        "2023-10-15": 1671.2319047619048,
        "2023-11-15": 3120.7114705882354,
        "2023-12-15": 4179.142105263157,
        "2024-01-15": 5574.82,
        "2024-02-15": 3663.7678947368418,
        "2024-03-15": 5016.195128205128,
        "2024-04-15": 2374.0446153846156,
        "2024-05-15": 1590.6782,
        "2024-06-15": 2482.7492500000003,
        "2024-07-15": 1620.3457954545456,
        "2024-08-15": 1590.227142857143,
        "2024-09-15": 1518.3512631578947
    },
    "notion.so": {
        "2022-09-15": 2107.502826362484,
        "2022-10-15": 731.526301775148,
        "2022-11-15": 209.01261642675698,
        "2022-12-15": 216.12171029668414,
        "2023-01-15": 678.9770544323484,
        "2023-02-15": 664.9401247848538,
        "2023-03-15": 266.6964491264132,
        "2023-04-15": 279.46401113172544,
        "2023-05-15": 218.70254444444444,
        "2023-06-15": 84.4944217252396,
        "2023-07-15": 244.16660268714008,
        "2023-08-15": 182.85000941619586,
        "2023-09-15": 266.93147179067864,
        "2023-10-15": 276.5853503709811,
        "2023-11-15": 412.46670109007465,
        "2023-12-15": 449.88739035087724,
        "2024-01-15": 394.2977605633802,
        "2024-02-15": 719.4580000000001,
        "2024-03-15": 453.9891666666667,
        "2024-04-15": 326.2280769230769,
        "2024-05-15": 79.20444444444445,
        "2024-06-15": 120.702,
        "2024-07-15": 2895.1504545454545,
        "2024-08-15": 336.175,
        "2024-09-15": 515.2255474452554
    }
}