import React, {useEffect} from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import {fetchData} from "../../store/dataApi.reducer";
import LoadingSpinnerComponent from "../../components/LoadingSpinnerComponent/LoadingSpinnerComponent";
import {store} from "../../store/store";


function withDataFetchAndTitle(GraphComponent, dataPath, title, maxWidth = "lg", sx = {}) {
    return function DataPageComponent() {
        // let data = useSelector(state => state.dataApi.data)
        let data = useSelector(state => ({...state.dataApi.data}))
        let loading = useSelector(state => ({...state.dataApi.loading}))
        let dispatch = useDispatch()
        useEffect(() => {
            if (!Object.hasOwn(loading, dataPath)) {
                dispatch(fetchData({path: dataPath}))
            }
        }, [dispatch, loading])

        return <>
            <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
                <Typography variant="h4" color="inherit" component="div">
                    {title}
                </Typography>
            </Container>
            <Container maxWidth={maxWidth} sx={sx}>
                {data[dataPath] === undefined ? <LoadingSpinnerComponent/> : <>
                    <GraphComponent data={data[[dataPath]]}/>
                </>}
            </Container>
        </>
    };
}

export default withDataFetchAndTitle