import {
    billionFormat,
    millionFormat,
    noFormat,
    thousandsFormat
} from "../components/Plots/TimeLinePlot/timeLinePlotHelpers";

export const objectValuesMap = (object, mapFunction) => {
    const newObject = Object()

    Object.keys(object).forEach(key => (newObject[key] = mapFunction(object[key])))

    return newObject
}

export const roundNumber = (number, decimalPoints) => {
    let factor = 10 ** decimalPoints
    return Math.round((number + Number.EPSILON) * factor) / factor
}

export const getNumberRounder = (decimalPoints) => (number) => roundNumber(number, decimalPoints)


export const getObjectValuesMapper = (mapFunction) => {
    return (object) => (objectValuesMap(object, mapFunction))
}

export const getObjectDecimalRounder = (decimals) => getObjectValuesMapper(
    (number) => roundNumber(number, decimals))

export const getOrderOfMagnitude = (number) => {
    console.log(`max Y: ${number}`)
    const orders = [
        {limit: 1e3, ret: ["", 1]},
        {limit: 1e6, ret: ["[k]", 1000]},
        {limit: 1e9, ret: ["[mil]", 1000000]},
        {limit: Infinity, ret: ["[bil]", 100000000]}
    ];

    for (let i = 0; i < orders.length; i++) {
        if (Math.abs(number) < orders[i].limit) {
            return orders[i].ret;
        }
    }

    return undefined;
}

export const getYAxisFormatFun = (orderOfMagnitude) => {
    switch (orderOfMagnitude) {
        case 1:
            return noFormat
        case 1000:
            return thousandsFormat
        case 1000000:
            return millionFormat
        case 1000000000:
            return billionFormat
        default:
            return noFormat
    }
}


export const getFormatFun = (number, scaleFactor = 0) => {
    if (number > 1000000000 * 10 ** scaleFactor) {
        return billionFormat
    } else if (number > 1000000 * 10 ** scaleFactor) {
        return millionFormat
    } else if (number > 1000 * 10 ** scaleFactor) {
        return thousandsFormat
    } else {
        return noFormat
    }
}


export function mapObjectValues(obj, mapFn) {
    let result = {};
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            result[key] = mapFn(obj[key]);
        }
    }
    return result;
}


export let trailingAverage = (windowSize) => (data) => {
    if (windowSize === undefined || windowSize === null) {
        return data;
    }
    let result = {};
    const sortedKeys = Object.keys(data).sort();

    sortedKeys.forEach((key, index) => {
        let sum = 0;
        let count = 0;
        let windowStart = index - windowSize + 1; // Adjust for trailing average

        for (let i = windowStart; i <= index; i++) {
            if (i >= 0 && i < sortedKeys.length) {
                sum += data[sortedKeys[i]];
                count++;
            }
        }
        result[key] = sum / count;
    });

    return result;
}
export let normalizeData = (data) => {
    console.log(data)
    // Object to store the sum for each date
    const dateSums = {};

    // Calculate the total sum for each date across all keys
    for (const key in data) {
        for (const date in data[key]) {
            if (!dateSums[date]) {
                dateSums[date] = 0;
            }
            dateSums[date] += data[key][date];
        }
    }

    // Normalize the data for each key based on the sum for each date
    const normalizedData = JSON.parse(JSON.stringify(data)); // Deep copy of original data
    for (const key in normalizedData) {
        for (const date in normalizedData[key]) {
            normalizedData[key][date] = (normalizedData[key][date] / dateSums[date]) * 100;
        }
    }

    return normalizedData;
}


function assertDataIntegrity(data) {
    const sortedKeys = Object.keys(data).sort();
    let previousMonth = null;
    let previousYear = null;

    sortedKeys.forEach(key => {
        const currentDate = new Date(key);
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();

        if (previousMonth !== null && previousYear !== null) {
            const nextMonth = (previousMonth + 1) % 12;
            const nextYear = previousMonth === 11 ? previousYear + 1 : previousYear;

            if (currentMonth !== nextMonth || currentYear !== nextYear) {
                throw new Error(`Missing or incorrect month/year sequence between ${new Date(previousYear, previousMonth).toISOString().slice(0, 7)} and ${key}`);
            }
        }

        previousMonth = currentMonth;
        previousYear = currentYear;
    });
}


export function getUniqueKeysOfNestedDicts(dictOfDicts) {
    // Create a Set to store unique keys
    const uniqueKeys = new Set();

    // Iterate through each key in the outer dictionary
    for (const key in dictOfDicts) {
        if (dictOfDicts.hasOwnProperty(key)) {
            const nestedDict = dictOfDicts[key];
            // Iterate through each key in the nested dictionary and add to the Set
            for (const nestedKey in nestedDict) {
                if (nestedDict.hasOwnProperty(nestedKey)) {
                    uniqueKeys.add(nestedKey);
                }
            }
        }
    }

    // Convert Set to Array, sort it, and return
    return Array.from(uniqueKeys).sort();
}

function filterNestedDictByDate(nestedDict, dateThreshold) {
    const thresholdDate = new Date(dateThreshold);

    let filteredDict = {};

    // Iterating over the entries of the outer dictionary
    for (const [key, innerDict] of Object.entries(nestedDict)) {
        // Filter the inner dictionary
        let filteredInnerDict = {};
        for (const [dateKey, value] of Object.entries(innerDict)) {
            if (new Date(dateKey) >= thresholdDate) {
                filteredInnerDict[dateKey] = value;
            }
        }

        // Adding the filtered inner dictionary to the result
        if (Object.keys(filteredInnerDict).length > 0) {
            filteredDict[key] = filteredInnerDict;
        }
    }
    return filteredDict;
}

function findAdjustedDate(nestedDict, offsetMonths) {
    let maxDate = new Date(0); // Initialize to earliest possible date

    // Traverse the nested dictionary
    for (const outerKey in nestedDict) {
        const innerDict = nestedDict[outerKey];
        for (const dateKey in innerDict) {
            const currentDate = new Date(dateKey);
            if (currentDate > maxDate) {
                maxDate = currentDate; // Update maxDate if a later date is found
            }
        }
    }

    // Subtract the offset in months
    maxDate.setMonth(maxDate.getMonth() - offsetMonths);

    // Set the date to the first of the resulting month
    maxDate.setDate(1);

    return maxDate;
}

export function mostCommon(arr) {
    // Count occurrences
    const counts = arr.reduce((acc, value) => {
        acc[value] = (acc[value] || 0) + 1;
        return acc;
    }, {});

    // Convert to [element, count] pairs and sort by count in descending order
    return Object.entries(counts).sort((a, b) => b[1] - a[1]);
}


export function filterNestedDictWithOffset(nestedDict, offsetMonths) {
    return filterNestedDictByDate(nestedDict, findAdjustedDate(nestedDict, offsetMonths))
}

export const extractSubDict = (originalObject, subDictTitle) => {
    return Object.fromEntries(
        Object.entries(originalObject).map(([key, value]) => [key, value[subDictTitle]])
    );
}

export function getFilenameFromUrl(url) {
    const decodedUrl = decodeURIComponent(url);
    const segments = decodedUrl.split('/');
    return segments.pop();
}