import React from "react";
import withDataFetchAndTitle from "../../components/DataLoadPage";

import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {getFilenameFromUrl, getFormatFun, roundNumber} from "../../../common/data_helpers";

const PrivcoPage = ({data}) => {
    let shareUrl = data['xlsx_download_url']
    return <div>
        <a href={shareUrl} target={"_blank"}>{getFilenameFromUrl(shareUrl)}</a>
        <div style={{height: '25px'}}></div>
        <GrowthTable tableData={data['privco_table']}/>
    </div>
}

const GrowthTable = ({tableData}) => {
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Company Name</TableCell>
                        <TableCell>Industry</TableCell>
                        <TableCell>1-Year Revenue Growth (%)</TableCell>
                        <TableCell>2023 Revenues</TableCell>
                        <TableCell>Website</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableData.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell>{row["Company Name"]}</TableCell>
                            <TableCell>{row.Industry}</TableCell>
                            <TableCell>{roundNumber(row["1-Year Revenue Growth (%)"], 0)}%</TableCell>
                            <TableCell>
                                {row["2023 Revenues"] ? getFormatFun(row["2023 Revenues"])(row["2023 Revenues"]) : "N/A"}
                            </TableCell>
                            <TableCell>
                                {row.Website ? (
                                    <a
                                        href={row.Website.includes('http') ? row.Website : `https://${row.Website}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {row.Website}
                                    </a>
                                ) : (
                                    "N/A"
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default withDataFetchAndTitle(PrivcoPage, 'privco/table', 'Privco - AI Companies')