import React from "react";
import TimeLinePlotWithSettings from "../../../components/Plots/TimeLinePlot/TimeLinePlotWithSettings";
import {extractSubDict} from "../../../common/data_helpers";

const WorkforcePageAlexStyle = ({data}) => (
    <>
        <TimeLinePlotWithSettings data={extractSubDict(data, 'employee_counts_monthly')}
                                  title={'Linkedin Employee Counts'} yFormat=" >-d"/>
        <TimeLinePlotWithSettings data={extractSubDict(data, 'employee_counts_yoy')}
                                  title={'Linkedin Employee Counts YoY'} yFormat=" >-d"/>
        <TimeLinePlotWithSettings data={extractSubDict(data, 'employee_counts_mom')}
                                  title={'Linkedin Employee Counts MoM'} yFormat=" >-d"/>
    </>
)



export default WorkforcePageAlexStyle
