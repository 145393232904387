import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import GraphComponent from "../../../../components/Plots/GraphComponent/GraphComponent";
import {convert} from "../../../../components/Plots/GraphComponent/GraphComponent.helpers";
import Delayed from "../../../../components/Delayed/Delayed";
import withDataFetchAndTitle from "../../../components/DataLoadPage";


const Graphs = ({data}) => {
    return <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
        <RegionGraphSection data={data} region={'China Local'}/>
        <RegionGraphSection data={data} region={'China International'}/>
        <RegionGraphSection data={data} region={'India Local'}/>
        <RegionGraphSection data={data} region={'India International'}/>
    </Container>
}

let RegionGraphSection = ({data, region}) => <>
    <Typography variant="h5" color="inherit" component="div">
        {region}
    </Typography>
    <Delayed waitBeforeShow={0}>
        <GraphSection data={data} region={region} metric={'Cycles'} calculation={'Totals'}/>
    </Delayed>

    <Delayed waitBeforeShow={200}>
        <GraphSection data={data} region={region} metric={'Cycles'} calculation={'YOY'}/>
    </Delayed>

    <Delayed waitBeforeShow={300}>
        <GraphSection data={data} region={region} metric={'Cycles'} calculation={'YOY2'}/>
    </Delayed>

    <Delayed waitBeforeShow={400}>
        <GraphSection data={data} region={region} metric={'Cycles'} calculation={'Percentage of 2019'}/>
    </Delayed>
</>

let GraphSection = ({data, region, metric, calculation}) =>
    <GraphComponent
        data={convert(data[region][metric][calculation])}
        title={`${region} ${metric} - ${calculation}`}
        xaxis={`${metric} ${calculation}`}
    />


export default withDataFetchAndTitle(Graphs, 'flight_data/regional_flights', 'Flight Data - Cirium Regional Flights')
