import {DashboardViews} from "./views";
import {ViewCreatorInterface} from "./ViewCreatorInterface";

export const getProjectRoutes = (projectApiList) => {
    let flatViews = []

    for (const project of projectApiList) {
        if (Object.hasOwnProperty.call(project, 'projectName')) {
            for (const view of project.views) {
                flatViews.push({
                    path: `${project.urlPrefix}/${view.url}`,
                    element: prepareView(view.viewDefinition)
                })
            }
        }
    }
    return flatViews;
}

let prepareView = (viewDefinition) => {
    let dashboardView = DashboardViews[viewDefinition.name];
    if (dashboardView instanceof ViewCreatorInterface) {
        return dashboardView.create(viewDefinition.params);
    }
    return dashboardView
}
