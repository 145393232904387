import * as React from 'react';
import Divider from "@mui/material/Divider";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {Collapse} from "@mui/material";
import List from "@mui/material/List";
import {getIconComponent} from "./iconImports";


let ItemsListComponent = ({projectApiList}) => {
    return <>
        {projectApiList.map((item, index) => <ItemComponent key={index} item={item}/>)}
    </>
}

let ItemComponent = ({item}) => {
    if (Object.hasOwn(item, 'projectName')) {
        return <ProjectComponent project={item}/>;
    } else if (Object.hasOwn(item, 'divider') && item.divider === 'divide') {
        return <Divider sx={{my: 1}}/>
    }

}

const ProjectComponent = ({project}) => {
    let Icon = getIconComponent(project.icon)
    const navigate = useNavigate();
    let [open, setOpen] = useState(false)

    return <>
        <ListItemButton onClick={() => setOpen(!open)}>
            <ListItemIcon>
                <Icon style={{height: 25, width: 24}}/>
            </ListItemIcon>
            <ListItemText primary={project.projectName}/>
            {open ? <ExpandLess/> : <ExpandMore/>}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                {project.views.map(({title, url, icon}) => {
                        let SubIcon = getIconComponent(icon);
                        return <ListItemButton sx={{pl: 4}} onClick={() => navigate(`${project.urlPrefix}/${url}`)}>
                            <ListItemIcon>
                                <SubIcon style={{height: 25, width: 24}}/>
                            </ListItemIcon>
                            <ListItemText primary={title}/>
                        </ListItemButton>
                    }
                )}

            </List>
        </Collapse>
    </>
}


export default ItemsListComponent