import { signOut } from 'aws-amplify/auth';
import { useNavigate } from "react-router-dom";
import DashboardContent from "./DashboardContent";

const Dashboard = () => {
    const navigate = useNavigate();
    const handleLogout = async () => {
        try {
            await signOut();
            console.log("log out successss");
            navigate("/signin");
        } catch (error) {
            console.log("error logging out");
        }
    };

    return (
        <div>
            <DashboardContent handleLogout={handleLogout}/>
        </div>
    );
}

export default Dashboard;