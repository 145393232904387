import CiriumFlightsPage from "../flight_data/CiriumFlights/CiriumFlightsPage";
import SRSForwardGraphsPage from "../flight_data/SRSForwardGraphs/SRSForwardGraphsPage";
import RegionalFlightsPage from "../flight_data/CiriumFlights/RegionalFlightsPage";
import BillSemrushPage from "../billdotocom/semrush/BillSemrush";
import DivvyPage from "../billdotocom/Divvy";
import StackedViewCreator from "../../components/stacked_viewer/StackedViewCreator";
import SRSRoutesPage from "../flight_data/SRSRoutes/SRSRoutesPage";
import UBSPage from "../flight_data/UBS/UBSPage";
import FlightRadarPage from "../flight_data/FlightRadar/FlightRadarPage";
import TSAPage from "../flight_data/TSAPage/TSAPage";
import ASMPage from "../flight_data/ASM/ASMPage";
import FleetAgePage from "../flight_data/FleetAge/FleetAgePage";
import FlightDataOrdersPage from "../flight_data/EventsDashboard/FlightDataOrdersPage";
import FlightDataFirstFlightsPage from "../flight_data/EventsDashboard/FlightDataFirstFlightPage";
import GitlabSales from "../gitlab/sales_team/GitlabSales";
import GitlabPlanningPage from "../gitlab/categories/GitlabPlanningPage";
import AmznCESalesPage from "../amzn/ce_sales/CESales";
import CECompetitors from "../amzn/ce_sales/CECompetitors";
import BuyWithPrimePage from "../amzn/shopify/BuyWithPrime";
import DatadogWorkforcePage from "../datadog/workforce/DatadogWorkforcePage";
import DatadogHGInsightsPage from "../datadog/workforce/DatadogHGInsightsPage";
import DatadogSemrush from "../datadog/DatadogSemrush";
import DatadogBuyerIntentPage from "../datadog/DatadogBuyerIntentPage";
import DatadogCncfGithub from "../datadog/DatadogCncfGithub";
import DatadogOpenTelemetryGithub from "../datadog/DatadogOpenTelemetryGithub";
import DatadogSecuritySales from "../datadog/DatadogSecuritySales";
import AutodeskBuyerIntentPage from "../autodesk/AutodeskBuyerIntentPage";
import AutodeskG2Page from "../autodesk/AutodeskG2Page";
import AutodeskSemrush from "../autodesk/AutodeskSemrush";
import WorkforceViewCreator from "../../components/workforce/WorkforceViewCreator";
import ShopifyBuiltwithPage from "../shopify/ShopifyBuiltWithPage";
import ShopifyPublicWWW from "../shopify/ShopifyPublicWWW";
import AppAnnieDexcomCreator from "../../components/app_annie/AppAnnieDexcomCreator";
import SemrushGraphsViewCreator from "../../components/semrush/SemrushGraphsViewCreator";
import ProductivityAppAnnieSummary from "../productivity/ProductivityAppAnnieSummary";
import ProductivitySemrushPage from "../productivity/semrush/SemrushPage";
import ProductivityG2Page from "../productivity/g2/G2Page";
import BuyerIntentPage from "../productivity/g2/BuyerIntentPage";
import BuiltwithPage from "../productivity/builtwith/BuiltwithPage";
import ProductivityHGInsightsPage from "../productivity/ProductivityHGInsightsPage";
import ProductivityWorkforcePage from "../productivity/ProductivityWorkforcePage";
import MondayAcuityLinkedin from "../productivity/MondayAcuityLinkedin";
import MondayGoogleTrends from "../productivity/MondayGoogleTrends";
import MondayIndeed from "../productivity/MondayIndeed";
import ETFEqualWeightsPage from "../etfs/equal_weights/ETF - equal weights";
import EtfSpy from "../etfs/spy/EtfSpy";
import IBKRAppAnnie from "../ibkr/IBKRAppAnnie";
import IBKRAppAnnieSummary from "../ibkr/IBKRAppAnnieSummary";
import IBKRSemrush from "../ibkr/IBKRSemrush";
import GithubStars from "../snowflake/GithubStars";
import DBEngines from "../snowflake/DBEngines";
import SnowflakeHGInsightsPage from "../snowflake/SnowflakeHGInsightsPage";
import SnowIndeed from "../snowflake/SnowIndeed";
import SFOfficesTable from "../loopnet/SFOfficesPage";
import MongoDBEngines from "../mongodb/MongoDBEngines";
import MongoGithubStars from "../mongodb/MongoGithubStars";
import MongoIndeed from "../mongodb/MongoIndeed";
import MongoGoogleTrends from "../mongodb/MongoGoogleTrends";
import StackOverflowSurveyComponent from "../mongodb/MongoStackOverflow";
import LLMsSemrush from "../llms/LLMsSemrush";
import CPNGSemrush from "../cpng/CPNGSemrush";
import FolioSemrush from "../folio/FolioSemrush";
import FolioGuardSemrush from "../folio/FolioGuardSemrush";
import FolioBuiltWith from "../folio/FolioBuiltWith";
import OtherIndeed from "../other/OtherIndeed";
import OtherAcuityLinkedin from "../other/OtherAcuityLinkedin";
import PrivcoPage from "../privco/PrivcoPage";


export const DashboardViews = {
    'StackedView': new StackedViewCreator(),
    'WorkforceView': new WorkforceViewCreator(),
    'AppAnnieDexcomView': new AppAnnieDexcomCreator(),
    'SemrushGraphsView': new SemrushGraphsViewCreator(),
    'CiriumFlightsPage': CiriumFlightsPage,
    'SRSForwardGraphsPage': SRSForwardGraphsPage,
    'RegionalFlightsPage': RegionalFlightsPage,
    'UBSPage': UBSPage,
    'BillSemrushPage': BillSemrushPage,
    'DivvyPage': DivvyPage,
    'SRSRoutesPage': SRSRoutesPage,
    'FlightRadarPage': FlightRadarPage,
    'TSAPage': TSAPage,
    'ASMPage': ASMPage,
    'FleetAgePage': FleetAgePage,
    'FlightDataOrdersPage': FlightDataOrdersPage,
    'FlightDataFirstFlightsPage': FlightDataFirstFlightsPage,
    'GitlabSales': GitlabSales,
    'GitlabPlanningPage': GitlabPlanningPage,
    'AmznCESalesPage': AmznCESalesPage,
    'CECompetitors': CECompetitors,
    'BuyWithPrimePage': BuyWithPrimePage,
    'DatadogWorkforcePage': DatadogWorkforcePage,
    'DatadogHGInsightsPage': DatadogHGInsightsPage,
    'DatadogSemrush': DatadogSemrush,
    'DatadogBuyerIntentPage': DatadogBuyerIntentPage,
    'DatadogCncfGithub': DatadogCncfGithub,
    'DatadogOpenTelemetryGithub': DatadogOpenTelemetryGithub,
    'DatadogSecuritySales': DatadogSecuritySales,
    'AutodeskBuyerIntentPage': AutodeskBuyerIntentPage,
    'AutodeskG2Page': AutodeskG2Page,
    'AutodeskSemrush': AutodeskSemrush,
    'ShopifyBuiltwithPage': ShopifyBuiltwithPage,
    'ShopifyPublicWWW': ShopifyPublicWWW,
    'ProductivityAppAnnieSummary': ProductivityAppAnnieSummary,
    'ProductivitySemrushPage': ProductivitySemrushPage,
    'ProductivityG2Page': ProductivityG2Page,
    'BuyerIntentPage': BuyerIntentPage,
    'BuiltwithPage': BuiltwithPage,
    'ProductivityHGInsightsPage': ProductivityHGInsightsPage,
    'ProductivityWorkforcePage': ProductivityWorkforcePage,
    'MondayIndeed': MondayIndeed,
    'MondayAcuityLinkedin': MondayAcuityLinkedin,
    'MondayGoogleTrends': MondayGoogleTrends,
    'ETFEqualWeightsPage': ETFEqualWeightsPage,
    'EtfSpy': EtfSpy,
    'IBKRAppAnnie': IBKRAppAnnie,
    'IBKRAppAnnieSummary': IBKRAppAnnieSummary,
    'IBKRSemrush': IBKRSemrush,
    'GithubStars': GithubStars,
    'DBEngines': DBEngines,
    'SnowflakeHGInsightsPage': SnowflakeHGInsightsPage,
    'SnowIndeed': SnowIndeed,
    'SFOfficesTable': SFOfficesTable,
    'MongoDBEngines': MongoDBEngines,
    'MongoGithubStars': MongoGithubStars,
    'MongoIndeed': MongoIndeed,
    'MongoGoogleTrends': MongoGoogleTrends,
    'StackOverflowSurveyComponent': StackOverflowSurveyComponent,
    'LLMsSemrush': LLMsSemrush,
    'CPNGSemrush': CPNGSemrush,
    'FolioSemrush': FolioSemrush,
    'FolioGuardSemrush': FolioGuardSemrush,
    'FolioBuiltWith': FolioBuiltWith,
    'OtherIndeed': OtherIndeed,
    'OtherAcuityLinkedin': OtherAcuityLinkedin,
    'PrivcoPage': PrivcoPage,
}