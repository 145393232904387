/** @jsxImportSource @emotion/react */
import * as React from 'react';
import {createTheme, styled, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LogoutIcon from '@mui/icons-material/Logout';
import {Button} from "@mui/material";
import {Route, Routes, useNavigate} from "react-router-dom";
import ExportsPage from "./exports/Exports";
import ExperimentsPage from "./experiments/ExperimentsPage";
import {ReactComponent as ObernLogo} from '../components/logos/oberndev.svg';
import TextCruncherEvaluation from "./textCruncher/TextCruncherEvaluation";
import ItemsListComponent from "./projects_navigation/ListItemsComponent";
import {projectsData} from "./projects_navigation/hardCodedApiResponse";
import {getProjectRoutes} from "./projects_navigation/RoutesGeneratorComponent";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

const mdTheme = createTheme({
        palette: {
            primary: {
                main: '#4d5656', // Replace with your desired color
            },
        }
    }
)

function DashboardContent({handleLogout}) {
    const [open, setOpen] = React.useState(true);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    const routes = getProjectRoutes(projectsData);


    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{display: 'flex'}}>
                <CssBaseline/>

                {open ? <Drawer variant="permanent" open={true}
                                sx={{
                                    boxSizing: 'content-box',
                                    height: '100vh',
                                }}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1],
                        }}
                    >
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon/>
                        </IconButton>
                    </Toolbar>
                    <Divider/>
                    <List component="nav">
                        <ItemsListComponent projectApiList={projectsData}/>
                        {/*<OtherListItems navigate={navigate}/>*/}

                    </List>
                </Drawer> : null}
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <AppBar position="absolute" open={open}>
                        <Toolbar
                            sx={{
                                pr: '24px', // keep right padding when drawer closed
                            }}
                        >
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                onClick={toggleDrawer}
                                sx={{
                                    marginRight: '36px',
                                    ...(open && {display: 'none'}),
                                }}
                            >
                                <MenuIcon/>
                            </IconButton>
                            {/*<div style={{display: 'flex', alignItems: 'center'}}>*/}
                            <ObernLogo style={{width: 32, height: 32, marginRight: 15}}/>
                            <Typography
                                component="h1"
                                variant="h6"
                                color="inherit"
                                noWrap
                                sx={{flexGrow: 1}}
                            >
                                obern.dev dashboard
                            </Typography>
                            {/*</div>*/}
                            <Button
                                variant="outlined"
                                color="inherit"
                                endIcon={<LogoutIcon/>}
                                onClick={handleLogout}
                            >
                                Logout
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <Toolbar/>
                    <Routes>
                        {routes.map(({path, element: Element}) => (
                            <Route key={path} path={path} element={<Element/>}/>
                        ))}
                        <Route path='experiment' element={<ExperimentsPage/>}/>
                        <Route path='exports' element={<ExportsPage/>}/>
                        <Route path='text_cruncher/procore' element={<TextCruncherEvaluation/>}/>

                        {/*<Route path='focus/files' element={<FocusDataList/>}/>*/}
                        {/*<Route path='focus/upload' element={<FocusFileUploadPage/>}/>*/}
                        {/*<Route path='focus/actions/' element={<ActionsViewPage/>}/>*/}
                        {/*<Route path='focus/actions/:id' element={<ActionViewPage/>}/>*/}

                    </Routes>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default DashboardContent