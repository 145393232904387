import React from "react";

export const MyCustomPoint = (invisibleLines) => (props) => {
    const {x, y, size, color, datum} = props

    if (invisibleLines[datum.id]) {
        return null
    }

    if (datum && datum.isModified) {
        // Drawing an example triangle for modified points
        return (
            <circle cx={x} cy={y} r={8} fill={color}/>
        )
    }
    // Default circle for unmodified points
    return (
        <circle cx={x} cy={y} r={size / 2} fill={color}/>
    )
}