import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {signIn} from 'aws-amplify/auth';
import {useNavigate} from "react-router-dom";
import {Alert, Backdrop, CircularProgress, Snackbar} from "@mui/material";
import {useState} from "react";

const theme = createTheme();

export default function SignIn() {
    const navigate = useNavigate();
    const [backdropOpen, setBackdrop] = useState(false);
    const [alertOpen, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        try {
            setBackdrop(true);
            await signIn({
                username: data.get('email'),
                password: data.get('password')
            });
            navigate("/");
        } catch (e) {
            setAlertMessage(e.name + ': ' + e.message);
            setAlert(true);
        }
    };


    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={backdropOpen}
                    onClick={() => setBackdrop(false)}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <Snackbar
                    sx={{height: "100%"}}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}
                    open={alertOpen}
                    autoHideDuration={6000}
                    onClose={() => {
                        setAlert(false)
                        setBackdrop(false)
                    }}
                >
                    <Alert
                        onClose={() => {
                            setAlert(false)
                            setBackdrop(false)
                        }}
                        severity="error" sx={{width: '100%'}}
                    >
                        {alertMessage}
                    </Alert>
                </Snackbar>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                        >
                            Sign In
                        </Button>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}