import React from "react";
import {getCurrentUser} from 'aws-amplify/auth';
import {Navigate} from "react-router-dom";

const ProtectedRoute = ({component}) => {
    const [isAuthenticated, setLoggedIn] = React.useState(true);

    React.useEffect(() => {
        (async () => {
            try {
                await getCurrentUser();
                setLoggedIn(true);
            } catch (e) {
                console.log('error', e);
                setLoggedIn(false);
            }
        })();
    });

    return (
        <>
            {isAuthenticated ? (
                React.createElement(component)
            ) : (
                <Navigate to="/signin"/>
            )}
        </>
    );
};

export default ProtectedRoute;