import { fetchAuthSession } from 'aws-amplify/auth';

export const apiUrl = 'https://0fk4vkuadc.execute-api.us-east-1.amazonaws.com/prod/'

export const apiCall = (path) => {
    return async () => {
        const { tokens } = await fetchAuthSession();
        const requestOptions = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${tokens.idToken.toString()}`,
            },
        };
        const response = await fetch(`${apiUrl}/${path}`, requestOptions);
        return await response.json();
    }
}